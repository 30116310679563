<template>
  <div>
    <h3 ref="intro">
      Area
    </h3>
    <p>
      Three dimensional (or 3D) objects look differently when seen from different directions or angles.
      <br> <br>
      One can draw a two dimensional (or 2D) figure of a 3D object as it is seen from a given direction. Such a figure is called a
      <i>view </i> or a <i> projection</i>.
      <br> <br>
      In a 2D view of a 3D object, one can see only those features that are visible from that particular direction. Thus, a single 2D view
      is generally not sufficient to describe all the features of a 3D object. <br><br>

      However, by a combination of multiple different views, one can fully describe a 3D object. <br> <br>

      Three commonly used views to describe a 3D object are:
      <ul>
        <li> <h5> Front View: </h5>The 2D view of a 3D object as seen from the front is called its front view. </li>
        <li> <h5> Side  View: </h5> The 2D view of a 3D object as seen from the side (can be left or right) is called its side view.</li>
        <li> <h5>  Top View </h5> The 2D view of a 3D object as seen from the top is called its top view. </li>
      </ul>
    </p>

    <h3 ref="pg">
      MagicGraph | Area of Geometric Shapes
    </h3>
    <br>
    <h5> Getting Started </h5>
    <p>
      In this MagicGraph, you will be drawing the front, top and side views of the given 3D object.
      On the top row, you will see the 3D object that you will be creating these views of. <br><br>
      On the bottom row, you are given three stacks of empty squares labeled as <i>Front</i>, <i>Top</i> and <i>Side</i>.
      By selectively filling the squares in these stacks, you can create all the three views.
      <br><br>
      Simply tap on an empty square to fill it. Tapping on a filled square will empty it.
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      You can tap on <i class="tester ma-1" /> icon  to check your answer. You can tap on the
      <i class="eraser ma-1" /> icon to erase and start over.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'SquareArea',
  async created () {
    await this.$store.dispatch('navigation/getData');
    let title = 'Area of Geometric Shapes';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Area',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>

import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 12, 10, -8],
        keepaspectratio: true, axis:false, ticks:{visible:true},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
    brd1.options.layer['image'] =10;
    brd1.options.layer['point'] =3;
//
    makeResponsive(brd1);
    // Creat titleStyle
    placeLogo(brd1);
    placeTitle(brd1, 'Area of a Rectangle', '(Fill the rectangle with unit squares)');
/////////////////////
    var analytics = placeShuffle(brd1);
    analytics.setLabel('Tap to Shuffle');
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    ////////////////////////////////////////////////////
    var erase = placeErase(brd1);
    erase.setLabel('Tap to Erase & Restart')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////
    var Pt1 = placePoint(brd1, -3, -3, 0, 'red', 'black');
    var Pt2 = placePoint(brd1,  3, 3, 0, 'red', 'black');
    var shape =placeSq(brd1, [()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt2.X(), ()=>Pt2.Y()],'white');
    shape.setAttribute({fillOpacity:0.7, borders:{visible:true, strokeColor:'black', strokeWidth:4}});
  //  var side1 = placeTapeHori(brd1, [()=>Pt1.X(), ()=>Pt1.Y()], [()=>Pt2.X(), ()=>Pt1.Y()],'');
  //  side1.setAttribute({highlight:true, point1:{visible:false},point2:{visible:false}, strokeWidth:4, strokeColor:'grey', label:{visible:false, color:'blue'}});
 ////////////////////////////////////////
  //  var side3 = placeTapeHori(brd1, [()=>Pt2.X(), ()=>Pt2.Y()], [()=>Pt1.X(), ()=>Pt2.Y()],'');
  //  side3.setAttribute({highlight:true, point1:{visible:false},point2:{visible:false},  strokeWidth:4, strokeColor:'grey', label:{visible:true, color:'blue'}});
//////////////////////////////////////////////////////
  //  var side2 = placeTapeVert(brd1, [()=>Pt2.X(), ()=>Pt1.Y()], [()=>Pt2.X(), ()=>Pt2.Y()],'');
  //  side2.setAttribute({highlight:true, fillOpacity:0.4,  point1:{visible:false},point2:{visible:false}, strokeWidth:4, strokeColor:'grey', label:{visible:true, color:'blue'}});
/////////////////////////////////////////////////////////////////////////////////////
  //  var side4 = placeTapeVert(brd1, [()=>Pt1.X(), ()=>Pt2.Y()], [()=>Pt1.X(), ()=>Pt1.Y()],'');
  //  side4.setAttribute({highlight:true, fillOpacity:0.4,  point1:{visible:false},point2:{visible:false},  strokeWidth:4, strokeColor:'grey', label:{visible:false, color:'blue'}});
//////////////////////////////////////////////////action///////////////////
var width=1;
var num_height=1;
//()=>Math.abs(Pt2.Y()-Pt1.Y());
var num_width=1;
//()=>Math.abs(Pt2.X()-Pt1.X());
var rectxy=[];
var rectsVisibility=[];
var create = function(){
for (let x=0; x<12; x++)
{   let rectx =[];
    let v =false;
    let vx =[];
    for (let y=0; y<12; y++)
    {
      var rect = brd1.create('image', ['/assets/purpleSquare.svg', [-3.+x*width, -3.+y*width], [1.0,1.0]], {visible:false, opacity:0.5, fixed:true});
      rectx.push(rect);
      vx.push(v);
    }
    rectxy.push(rectx);
    rectsVisibility.push(vx);
}
rectxy[0][0].setAttribute({visible:true});
}
create();
//////////////////////////////////////////Change Color ///////////////////////////////////////
function showWidth()
{
  for (let y=0; y<num_height.valueOf(); y++)
    {
      rectxy[num_width][y].setAttribute({visible:true});
    }
}
//////////////////////////////////////////////////////////
function hideWidth(){
  for (let x=0; x<=num_height.valueOf(); x++)
  {
      rectxy[num_width][x].setAttribute({visible:false});
  }
}
/////////////////////////////////////////////////////WIDTH CHANGE ////////////////////////////
function showHeight()
{
    for (let x=0; x<num_width.valueOf(); x++)
    {
      rectxy[x][num_height].setAttribute({visible:true});
    }
}
//////////////////////////////////////////////////////////
function hideHeight(){
  for (let x=0; x<=num_width.valueOf(); x++)
  {
      rectxy[x][num_height].setAttribute({visible:false});
  }
}
/////////////////////////////////////////////////////////
var plusWidth = placeImage(brd1, '/assets/plus.svg', 0.5, -5, 1, 0);
var minusWidth = placeImage(brd1, '/assets/minuss.svg', -1.5, -5, 1, 0);
plusWidth.on('down', function(){showWidth();num_width +=1; })
minusWidth.on('down', function(){if(num_width>1){num_width-=1;}else{num_width=1}; hideWidth();})
//////////////////////////////////////////////////// HEIGHT CHANGE ////////////////////////////
var plusHeight = placeImage(brd1, '/assets/plus.svg', -5, 0.5, 1, 0);
var minusHeight = placeImage(brd1, '/assets/minuss.svg', -5, -1.5, 1, 0);
plusHeight.on('down', function(){showHeight();num_height +=1;})
minusHeight.on('down', function(){if(num_height>1){num_height-=1;}else{num_height=1}; hideHeight();})
/////////////////////////////////////////////////////
placeBoldText(brd1, 0., -4.5, ()=>num_width.valueOf());
placeBoldText(brd1, -4.5, 0, ()=>num_height.valueOf());
////////////////////////////////////////////////////

placeText(brd1, 0, 8.5, 24 , function(){return 'Area = '+ num_width*num_height + ' ?'});

placeText(brd1, 0, 7.25, 24 , function(){return 'Number of Unit Square ='+ num_width.valueOf()+ ' x '+ num_height.valueOf() + ' = '+ num_width*num_height});
//placeText(brd1, 0, 6.5, 32, function(){return Pt2.X()});
var rght = placeImage(brd1, '/assets/checkmark.svg', 1.5, 8, 1.,  0);
rght.setAttribute({visible:()=>num_width==Pt2.X()-Pt1.X() && num_height == Pt2.Y()-Pt1.Y()});
    ////////////////////////////////////////////////////////////////////////////
    function movePt2(){
    Pt2.moveTo([3+Math.round(4*Math.random()), 3+Math.round(4*Math.random())],100);
    }

//    erase.on('down', function(){checkColorFront();checkColorTop(); checkColorSide();})

    function clearVisibility(){
      for (let x=0; x<12; x++)
           {
             for (let y=0; y<12; y++){
             rectxy[x][y].setAttribute({visible:false});
           }
            }
            rectxy[0][0].setAttribute({visible:true});
          }
    analytics.on('down', function(){
      clearVisibility();
      num_width=1;
      num_height=1;
      movePt2();
    });
   erase.on('down', function(){clearVisibility(); num_width=1; num_height=1;Pt2.moveTo([3,3], 100)});
///////////////////////////////////////////////////////////////////////
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
